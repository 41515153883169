import React, { useEffect, useState } from 'react';
import mapboxgl from 'mapbox-gl';
import Papa from 'papaparse';
import FilterAccordion from './FilterAccordion';
import FilterResultsSidebar from './FilterResultsSidebar';  // Import the new sidebar component
import 'mapbox-gl/dist/mapbox-gl.css';
import './App.css';

mapboxgl.accessToken = 'pk.eyJ1IjoiZGF2aWRjb25zY2lvdXNjb21tdW5pdGllc2NvbSIsImEiOiJjbTBlNWE4aHQwazd1MnFxNDd0c2gzMmhyIn0.NKXrXJz2Mfz8mloCqTDGaQ';

function App() {
  const [map, setMap] = useState(null);
  const [data, setData] = useState([]);
  // Group the data by Category from the ColumnsNames CSV
const groupedData = data.reduce((acc, item) => {
  if (item['Field Name'] !== 'search_link') {  // Exclude Zillow Link column
    if (!acc[item.Category]) {
      acc[item.Category] = [];
    }
    acc[item.Category].push(item);
  }
  return acc;
}, {});

  const [propertyData, setPropertyData] = useState([]);
  const [highlightedFeatureId, setHighlightedFeatureId] = useState(null);
  const [selectedProperty, setSelectedProperty] = useState(null); // Add state for the selected property

  // Sorting state and logic
  const [sortConfig, setSortConfig] = useState({ key: null, direction: 'ascending' });

  const handleSort = (key) => {
    let direction = 'ascending';
    if (sortConfig.key === key && sortConfig.direction === 'ascending') {
      direction = 'descending';
    }
    setSortConfig({ key, direction });
  };

  useEffect(() => {
    const initializeMap = async () => {
      const newMap = new mapboxgl.Map({
        container: 'map',
        style: 'mapbox://styles/mapbox/streets-v11',
        center: [-118.25, 34.05],
        zoom: 10,
      });

      newMap.on('load', async () => {
        // Add the centroid_layer GeoJSON source, with generateId to ensure unique feature IDs
        newMap.addSource('centroid_layer', {
          type: 'geojson',
          data: 'https://consciouscommunities.me/geoserver/wfs?service=WFS&version=1.0.0&request=GetFeature&typeName=mydata:centroid_layer&outputFormat=application/json',
          generateId: true  // Automatically generate unique IDs for each feature
          
        });

        // Add circle layer for dots (visible when zoomed out)
        newMap.addLayer({
          id: 'centroid_dots',
          type: 'circle',
          source: 'centroid_layer',
          minzoom: 0,
          maxzoom: 24,  // Switch to symbols after zoom level 13
          paint: {
            'circle-radius': 5,
            'circle-stroke-width': 1,
            'circle-stroke-color': '#000000',
            'circle-color': [
              'case',
              ['==', ['get', 'j_fact_2'], null], 'rgba(255, 255, 255, 0)',
              ['<', ['get', 'j_fact_2'], -50], '#00296B',
              ['>', ['get', 'j_fact_2'], 50], '#FFD700',
              ['interpolate', ['linear'], ['get', 'j_fact_2'], -50, '#00296B', 0, '#808080', 50, '#FFD700']
            ]
          }
        });

// Add symbol layer for rectangles (visible when zoomed in)
newMap.addLayer({
  id: 'centroid_symbols',
  type: 'symbol',
  source: 'centroid_layer',
  minzoom: 13,
  layout: {
    'text-field': [
      'concat',
      '$',
      [
        'case',
        // Handle millions (e.g., 1,200,000 -> 1.2m)
        ['>=', ['to-number', ['get', 'list_price']], 1000000],
        [
          'concat',
          ['slice', ['to-string', ['/', ['get', 'list_price'], 1000000]], 0, 3], // Get the first 2 digits for million
          'm'
        ],
        // Handle hundreds of thousands (e.g., 799,000 -> 799k)
        ['>=', ['to-number', ['get', 'list_price']], 100000],
        [
          'concat',
          ['slice', ['to-string', ['get', 'list_price']], 0, 3], // Get the first 3 digits
          'k'
        ],
        // Display other numbers as-is (e.g., under 100,000)
        ['to-string', ['get', 'list_price']]
      ]
    ],
    'text-size': 15,
    'text-anchor': 'left',
    'text-offset': [.75, 0],
    'text-allow-overlap': false,
  },
  paint: {
    'text-color': '#000000',
    'text-halo-color': '#FFFFFF',
    'text-halo-width': 12,
  },
});


        // Moveend event to update visible features
        newMap.on('moveend', () => {
          const visibleFeatures = newMap.queryRenderedFeatures({
            layers: ['centroid_dots', 'centroid_symbols'],
          });
        
          if (Array.isArray(visibleFeatures) && visibleFeatures.length > 0) {
            const features = visibleFeatures.map((feature) => ({
              ...feature.properties,  // Ensure you're including the properties
              id: feature.properties.id  // Use the properties' id instead of feature.id
            }));
            console.log('Visible features:', features);  // Log visible features for debugging
            setPropertyData(features);  // Store the features' properties in the state
          } else {
            setPropertyData([]);  // Reset property data
          }
        });
        

        // Log the IDs when data is loaded
        newMap.on('sourcedata', (e) => {
          if (e.sourceId === 'centroid_layer' && e.isSourceLoaded) {
            console.log('Centroid Layer source fully loaded');
          }
        });

        // Highlight feature on map click
        newMap.on('click', 'centroid_dots', (e) => {
          const clickedFeature = e.features[0];
          setHighlightedFeatureId(clickedFeature.id);
          setSelectedProperty(clickedFeature.properties);  // Directly use properties from the clicked feature
        });

        newMap.on('click', 'centroid_symbols', (e) => {
          const clickedFeature = e.features[0];
          const featureId = clickedFeature.properties.id;  // Use the 'id' from properties
          console.log('Clicked Feature ID:', featureId);  // Log the feature ID for debugging
        
          // Log propertyData to compare
          console.log('Property Data:', propertyData);
        
          // Find the matching property in propertyData based on the 'id' in properties
          const matchedProperty = propertyData.find(property => property.id === featureId);
        
          if (matchedProperty) {
            setHighlightedFeatureId(featureId);  // Highlight using the feature's actual ID
            setSelectedProperty(matchedProperty);  // Set the matched property for the sidebar display
          } else {
            console.warn(`No matching property found for Feature ID: ${featureId}`);
          }
        });
        
        
        setMap(newMap);
      });

      // Listen for missing symbol images and add them dynamically
      newMap.on('styleimagemissing', async (e) => {
        const id = e.id;
      
        if (id.startsWith('symbol-')) {
          const featureId = id.replace('symbol-', '');

          if (!newMap.hasImage(id)) {
            const feature = newMap.querySourceFeatures('centroid_layer', {
              filter: ['==', ['id'], parseInt(featureId)]
            })[0];

            if (!feature) {
              console.error(`Feature with id ${featureId} not found`);
              return;
            }

            const listPrice = feature.properties.list_price;

            try {
              console.log(`Symbol for ${id} not added.`);

              console.log(`Image for ${id} added successfully.`);
            } catch (error) {
              console.error(`Error creating image for ${id}: `, error);
            }
          }
        }
      });
    };

    if (!map) {
      initializeMap();
    }
  }, [map]);

  // Fetch and parse the CSV data for the sidebar
  useEffect(() => {
    Papa.parse('ColumnsNames - ColumnsNames.csv', {
      download: true,
      header: true,
      complete: (result) => {
        setData(result.data);
      },
    });
  }, []);

  // Use effect to update map based on highlightedFeatureId
  useEffect(() => {
    if (map && highlightedFeatureId !== null) {
      map.setPaintProperty('centroid_dots', 'circle-color', [
        'case',
        ['==', ['id'], highlightedFeatureId], '#FF0000',
        ['==', ['get', 'j_fact_2'], null], 'rgba(255, 255, 255, 0)',
        ['<', ['get', 'j_fact_2'], -50], '#00296B',
        ['>', ['get', 'j_fact_2'], 50], '#FFD700',
        ['interpolate', ['linear'], ['get', 'j_fact_2'], -100, '#00296B', 0, '#808080', 100, '#FFD700']
      ]);

    }
  }, [highlightedFeatureId, map]);

  const applyFilters = (filters) => {
    if (!map) return;

    const filterExpressions = Object.keys(filters).map((key) => {
      const filter = filters[key];
      if (filter.applied) {
        let geoJSONKey = key;

        if (filter.userInputType === 'Range Slider') {
          return [
            'all',
            ['>=', ['coalesce', ['number', ['get', geoJSONKey], -Infinity]], filter.selectedMin],
            ['<=', ['coalesce', ['number', ['get', geoJSONKey], Infinity]], filter.selectedMax],
          ];
        } else if (filter.userInputType === 'Choose Any') {
          return [
            'any',
            ...filter.selectedOptions.map(option => ['==', ['get', geoJSONKey], option])
          ];
        }
      }
      return null;
    }).filter(Boolean);

    const finalExpression = filterExpressions.length > 0 ? ['all', ...filterExpressions] : null;

    map.setFilter('centroid_dots', finalExpression);
    map.setFilter('centroid_symbols', finalExpression);

    map.once('idle', () => {
      const visibleFeatures = map.queryRenderedFeatures({ layers: ['centroid_dots', 'centroid_symbols'] });
      const features = visibleFeatures.map((feature) => feature.properties);
      setPropertyData(features);
    });
  };

  const resetFilter = (key) => {
    if (map) {
      map.setFilter('centroid_dots', null);
      map.setFilter('centroid_symbols', null);
    }
  };
  return (
<div style={{ display: 'flex', height: '100vh', overflow: 'hidden' }}>
      
      {/* Left Sidebar */}
      <div 
        id="filter-sidebar" 
        style={{ 
          flexBasis: '250px',  /* Use flex-basis for consistent width */
          backgroundColor: '#f4f4f4', 
          boxSizing: 'border-box',
          overflowY: 'auto',  /* Allow vertical scrolling if necessary */
        }}
      >
        <FilterAccordion applyFilters={applyFilters} resetFilter={resetFilter} />
      </div>
    
      {/* Map */}
      <div 
        id="map" 
        style={{ 
          flex: 1,  /* Map takes remaining space */
          position: 'relative' 
        }} 
      />
    
      {/* Right Sidebar */}
      <div 
  className="sidebar" 
  style={{ 
    flexBasis: '800px',  /* Consistent width for the right sidebar */
    backgroundColor: '#f4f4f4', 
    boxSizing: 'border-box',
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',  /* Ensure full height */
    position: 'relative',  /* Ensure horizontal scrollbar visibility */
  }}
>



<FilterResultsSidebar 
  data={data} 
  propertyData={propertyData} 
  sortConfig={sortConfig} 
  handleSort={handleSort} 
  map={map} 
  highlightedFeatureId={highlightedFeatureId} 
  setHighlightedFeatureId={setHighlightedFeatureId}
  selectedProperty={selectedProperty}  
  setSelectedProperty={setSelectedProperty}  
  groupedData={groupedData}  // Pass groupedData as a prop
/>


      </div>
    </div>
    
  );
  
}

export default App;
