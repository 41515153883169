import Papa from 'papaparse';
import React, { useState, useEffect } from 'react';
import { FaTrash } from 'react-icons/fa';
import Slider from 'rc-slider';
import 'rc-slider/assets/index.css';
import './FilterAccordion.css';

const FilterAccordion = ({ applyFilters, resetFilter }) => {
  const [filters, setFilters] = useState({});
  const [expanded, setExpanded] = useState(null);

  useEffect(() => {
    // Load and parse the CSV file
    Papa.parse('/ColumnsNames - ColumnsNames.csv', {
      download: true,
      header: true,
      complete: (result) => {
        const filterableData = result.data.filter((row) => row.Filterable === 'TRUE');
        const initialFilters = filterableData.reduce((acc, curr) => {
          const min = curr.Range ? parseInt(curr.Range.split('-')[0], 10) : 0;
          const max = curr.Range ? parseInt(curr.Range.split('-')[1], 10) : 100;
          const sliderIncrement = curr['Slider Increment'] ? parseFloat(curr['Slider Increment']) : 1; // Capture slider increment
          acc[curr['Field Name']] = {
            min,
            max,
            selectedMin: min,
            selectedMax: max,
            applied: false,
            category: curr.Category,
            label: curr['Name as it appears on the website'],
            userInputType: curr['User Input Type'],
            options: curr.Range ? curr.Range.split(', ') : [],
            selectedOptions: [],
            dataType: curr['Data Type'],
            prefix: curr.Prefix || '', // Capture the prefix from CSV
            suffix: curr.Suffix || '', // Capture the suffix from CSV
            numFormat: curr['Num Format'] || '0,0', // Capture number format
            sliderIncrement // Add slider increment to the filter object
          };
          return acc;
        }, {});
        setFilters(initialFilters);
      },
    });
  }, []);

  const handleSliderChange = (key, newValue) => {
    console.log(`Slider changed for ${key}: New Values - Min: ${newValue[0]}, Max: ${newValue[1]}`);
    
    setFilters((prevFilters) => ({
      ...prevFilters,
      [key]: { 
        ...prevFilters[key], 
        selectedMin: newValue[0] !== undefined ? newValue[0] : prevFilters[key].min,
        selectedMax: newValue[1] !== undefined ? newValue[1] : prevFilters[key].max
      },
    }));
  };
  

  const handleCheckboxChange = (key, value, checked) => {
    setFilters((prevFilters) => {
      const selectedOptions = prevFilters[key].selectedOptions;
      const updatedOptions = checked
        ? [...selectedOptions, value]
        : selectedOptions.filter(option => option !== value);

      return {
        ...prevFilters,
        [key]: { ...prevFilters[key], selectedOptions: updatedOptions }
      };
    });
  };

  const applyFilter = (key) => {
    const updatedFilters = { ...filters, [key]: { ...filters[key], applied: true } };
    
    let finalFilter = null;
    const selectedMin = parseFloat(filters[key].selectedMin);
    const selectedMax = parseFloat(filters[key].selectedMax);
  
    console.log("Selected Min:", selectedMin, "Selected Max:", selectedMax);
  
    const dataType = filters[key].dataType;
  
    console.log(`Handling filter for ${key}: Data Type = ${dataType}, User Input Type = ${filters[key].userInputType}`);
  
    if (dataType === 'String' && filters[key].userInputType === 'Choose Any') {
      const selectedOptions = filters[key].selectedOptions;
      finalFilter = [
        "any",
        ...selectedOptions.map(option => ["==", ["get", key], option])
      ];
    } else if (dataType === 'String' && filters[key].userInputType === 'Text') {
      finalFilter = ["==", ["get", key], filters[key].selectedText];
    } else if ((dataType === 'Integer' || dataType === 'Double') && filters[key].userInputType === 'Range Slider') {
      finalFilter = [
        "all",
        [">=", ["number", ["get", key]], selectedMin],
        ["<=", ["number", ["get", key]], selectedMax]
      ];
      console.log("Applying range slider filter:", finalFilter);
    } else if (dataType === 'Boolean' && filters[key].userInputType === 'Yes/No') {
      const selectedValue = filters[key].selectedOptions[0] === 'Yes' ? true : false;
      finalFilter = ["==", ["get", key], selectedValue];
    }
  
    if (finalFilter) {
      console.log("Final filter applied for", key, ":", finalFilter);
      applyFilters(updatedFilters);  // Only pass the updatedFilters
    }    
  
    // Update state after everything is processed, outside of the return statement
    setFilters(updatedFilters);
  };
  
  
  const resetSingleFilter = (key) => {
    setFilters((prevFilters) => {
      const updatedFilters = {
        ...prevFilters,
        [key]: { 
          ...prevFilters[key], 
          selectedMin: prevFilters[key].min,  // Reset to original static min
          selectedMax: prevFilters[key].max,  // Reset to original static max
          selectedOptions: [],  // Reset "Choose Any" selections
          applied: false  // Mark the filter as not applied
        },
      };

      let finalFilter = null;
      const dataType = prevFilters[key].dataType;
      
      // Reverse logic to reset filter
      if (dataType === 'String' && prevFilters[key].userInputType === 'Choose Any') {
        finalFilter = [
          "none", // This effectively resets the filter
          ...prevFilters[key].options.map(option => ["!=", ["get", key], option])
        ];
      } else if ((dataType === 'Integer' || dataType === 'Double') && prevFilters[key].userInputType === 'Range Slider') {
        finalFilter = [
          "all",
          [">=", ["number", ["coalesce", ["get", key], null]], prevFilters[key].min],
          ["<=", ["number", ["coalesce", ["get", key], null]], prevFilters[key].max]
        ];
      } else if (dataType === 'Boolean' && prevFilters[key].userInputType === 'Yes/No') {
        finalFilter = ["==", ["get", key], null];  // Reset the boolean filter
      }
      
      // Apply the reversed (reset) filter logic
      applyFilters(updatedFilters, finalFilter);  // Call applyFilters with the reset filter

      return updatedFilters;
    });
};

  const toggleAccordion = (key) => {
    setExpanded(expanded === key ? null : key); // Toggle the accordion item
  };

  // Get unique categories from the filters
  const categories = Array.from(new Set(Object.values(filters).map(filter => filter.category)));

  return (
    <div className="accordion">
      {categories.map((category) => (
        <div className="accordion-item" key={category}>
          <h2 className="accordion-header" onClick={() => toggleAccordion(category)}>
            {category}
          </h2>
          {expanded === category && (
            <div className="accordion-content">
              {Object.keys(filters)
                .filter(key => filters[key].category === category)
                .map(key => (
                  <div className="filter-item" key={key}>
                    <label>{filters[key].label}</label>

                    {filters[key].userInputType === 'Range Slider' && (
                      <div className="filter-slider">
                        <Slider
                          range
                          min={filters[key].min}  // Static min value
                          max={filters[key].max}  // Static max value
                          step={filters[key].sliderIncrement || 1}  // Use custom slider increment or default to 1
                          value={[filters[key].selectedMin, filters[key].selectedMax]}  // Selected range
                          onChange={(newValue) => handleSliderChange(key, newValue)}
                        />
                        <div className="slider-values">
                          <span>
                            {filters[key].prefix} {new Intl.NumberFormat('en-US', {
                              minimumFractionDigits: filters[key].numFormat.includes('.00') ? 2 : 0,
                              maximumFractionDigits: filters[key].numFormat.includes('.00') ? 2 : 0
                            }).format(filters[key].selectedMin)} {filters[key].suffix}
                          </span>
                          -
                          <span>
                            {filters[key].prefix} {new Intl.NumberFormat('en-US', {
                              minimumFractionDigits: filters[key].numFormat.includes('.00') ? 2 : 0,
                              maximumFractionDigits: filters[key].numFormat.includes('.00') ? 2 : 0
                            }).format(filters[key].selectedMax)} {filters[key].suffix}
                          </span>
                        </div>

                      </div>
                    )}

                    {filters[key].userInputType === 'Choose Any' && (
                      <div className="filter-checkbox">
                        {filters[key].options.map(option => (
                          <div key={option}>
                            <input
                              type="checkbox"
                              checked={filters[key].selectedOptions.includes(option)}
                              onChange={(e) => handleCheckboxChange(key, option, e.target.checked)}
                            />
                            <label>{option}</label>
                          </div>
                        ))}
                      </div>
                    )}

                    <div className="filter-actions">
                      <button onClick={() => applyFilter(key)}>Apply</button>
                      <FaTrash onClick={() => resetSingleFilter(key)} />
                    </div>
                  </div>
                ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );
};

export default FilterAccordion;
