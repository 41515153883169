import React, { useState } from 'react';
import numeral from 'numeral';

const DataTable = ({ data = [], propertyData = [], sortConfig, handleSort, highlightedFeatureId, setHighlightedFeatureId, map, setSelectedProperty }) => {


  console.log("Rendering DataTable with propertyData:", propertyData);  // Log to confirm data received
  
  // Exclude the 'search_link' (Zillow Link) column when grouping data
  const groupedData = data.reduce((acc, item) => {
    if (item['Field Name'] !== 'search_link') { // Exclude Zillow Link column
      if (!acc[item.Category]) {
        acc[item.Category] = [];
      }
      acc[item.Category].push(item);
    }
    return acc;
  }, {});
  

  const [currentPage, setCurrentPage] = useState(1);
  const rowsPerPage = 50; // Number of rows to show per page

  const sortedPropertyData = [...propertyData].sort((a, b) => {
    if (a[sortConfig.key] < b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? -1 : 1;
    }
    if (a[sortConfig.key] > b[sortConfig.key]) {
      return sortConfig.direction === 'ascending' ? 1 : -1;
    }
    return 0;
  });

  const [collapsedCategories, setCollapsedCategories] = useState(Object.keys(groupedData));

  const toggleCategoryCollapse = (category) => {
    setCollapsedCategories(prevState =>
      prevState.includes(category)
        ? prevState.filter(c => c !== category)
        : [...prevState, category]
    );
  };

  const getSortIndicator = (key) => {
    if (sortConfig.key === key) {
      return sortConfig.direction === 'ascending' ? '▲' : '▼';
    }
    return '';
  };

  // Pagination logic
  const indexOfLastRow = currentPage * rowsPerPage;
  const indexOfFirstRow = indexOfLastRow - rowsPerPage;
  const currentRows = sortedPropertyData.slice(indexOfFirstRow, indexOfLastRow);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Format values based on the data type, format, prefix, and suffix
  const formatValue = (value, format, prefix, suffix, dataType, fieldName, row) => {
    if (value == null || value === '') return 'N/A';

    // Apply link to the 'situsaddre' field using 'search_link'
    if (fieldName === 'situsaddre') {
      const zillowLink = row['search_link']; // Assume 'search_link' holds the Zillow URL
      return zillowLink ? <a href={zillowLink} target="_blank" rel="noopener noreferrer">{value}</a> : value;
    }

    if (dataType === 'Double' || dataType === 'Integer') {
      let formattedValue = numeral(value).format(format || '0,0'); // Default format: comma-separated
      return `${prefix || ''}${formattedValue}${suffix || ''}`;
    }

    if (dataType === 'Boolean') {
      return value ? 'Yes' : 'No';
    }

    // For String or other data types, return the value without formatting
    return value;
  };

  return (
<div style={{ overflowX: 'scroll', overflowY: 'hidden', width: '100%', display: 'block' }}> 

<table style={{ borderCollapse: 'collapse', width: '100%' }}>





        <thead>
          <tr>
            {Object.keys(groupedData).map((category) => (
              <th
                key={category}
                colSpan={groupedData[category].length}
                style={{ border: '1px solid black', padding: '8px' }}
              >
                <button onClick={() => toggleCategoryCollapse(category)}>
                  {collapsedCategories.includes(category) ? '+' : '-'} {category}
                </button>
              </th>
            ))}
          </tr>
          <tr>
            {Object.keys(groupedData).map((category) =>
              groupedData[category].map((col, index) => (
                !collapsedCategories.includes(category) || index === 0 ? (
                  <th
                    key={col['Field Name']}
                    colSpan={collapsedCategories.includes(category) && index === 0 ? groupedData[category].length : 1}
                    style={{ border: '1px solid black', padding: '8px', cursor: 'pointer' }}
                    onClick={() => handleSort(col['Field Name'])}
                  >
                    {col['Name as it appears on the website']} {getSortIndicator(col['Field Name'])}
                  </th>
                ) : null
              ))
            )}
          </tr>
        </thead>
        <tbody>
          {currentRows.length > 0 ? (
            currentRows.map((property, rowIndex) => (
              <tr
                key={rowIndex}
                className={property.id === highlightedFeatureId ? 'highlighted-row' : ''}
                style={property.id === highlightedFeatureId ? { backgroundColor: '#ffdd57' } : {}} // Highlight row with color
                onClick={() => {
                  console.log('Row clicked:', property.id);
                  if (map) {
                    setHighlightedFeatureId(property.id);
                    setSelectedProperty(property);  // Set the clicked property in the sidebar
                    // Highlight the feature by updating the circle-color on the map
                    map.setPaintProperty('centroid_layer', 'circle-color', [
                      'case',
                      ['==', ['get', 'id'], property.id], '#FF0000',  // Red for the clicked feature
                      ['==', ['get', 'j_fact_2'], null], 'rgba(255, 255, 255, 0)',  // Transparent fill for "null"
                      ['<', ['get', 'j_fact_2'], -50], '#00296B',  // Dark Blue
                      ['>', ['get', 'j_fact_2'], 50], '#FFD700',  // Yellow
                      ['interpolate', ['linear'], ['get', 'j_fact_2'], -100, '#00296B', 0, '#808080', 100, '#FFD700']  // Color ramp
                    ]);
                  } else {
                    console.error("Map is undefined");
                  }
                }}
                
                
              >

                {Object.keys(groupedData).map((category) =>
                  groupedData[category].map((col, index) => (
                    !collapsedCategories.includes(category) || index === 0 ? (
                      <td
                        key={col['Field Name']}
                        colSpan={collapsedCategories.includes(category) && index === 0 ? groupedData[category].length : 1}
                        style={{ border: '1px solid black', padding: '8px' }}
                      >
                        {formatValue(
                          property[col['Field Name']],
                          col['Num Format'], // Format from the CSV
                          col['Prefix'], // Prefix from the CSV
                          col['Suffix'], // Suffix from the CSV
                          col['Data Type'], // Data type from the CSV
                          col['Field Name'], // Field name for custom logic (like links)
                          property // The full row data for contextual info
                        )}
                      </td>
                    ) : null
                  ))
                )}
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={Object.keys(groupedData).reduce((acc, cat) => acc + groupedData[cat].length, 0)}
                style={{ textAlign: 'center', padding: '8px' }}
              >
                No data available
              </td>
            </tr>
          )}
        </tbody>
      </table>

      {/* Pagination Controls */}
      <div style={{ padding: '10px', textAlign: 'center', width: '100%' }}>

        {[...Array(Math.ceil(sortedPropertyData.length / rowsPerPage))].map((_, index) => (
          <button
            key={index + 1}
            onClick={() => paginate(index + 1)}
            style={{ margin: '0 5px', padding: '5px 10px' }}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default DataTable;
