import React from 'react';
import DataTable from './DataTable';  // Assuming this renders the table of filter results or data
import numeral from 'numeral';  // Import numeral for number formatting

const FilterResultsSidebar = ({ 
  data, 
  propertyData, 
  sortConfig, 
  handleSort, 
  map, 
  highlightedFeatureId, 
  setHighlightedFeatureId, 
  selectedProperty, 
  setSelectedProperty,
  groupedData  // Add groupedData as a prop
}) => {

  // Move the formatValue function here
  const formatValue = (value, format, prefix, suffix, dataType, fieldName, row) => {
    if (value == null || value === '') return 'N/A';

    // Apply link to the 'situsaddre' field using 'search_link'
    if (fieldName === 'situsaddre') {
      const zillowLink = row['search_link']; // Assume 'search_link' holds the Zillow URL
      return zillowLink ? <a href={zillowLink} target="_blank" rel="noopener noreferrer">{value}</a> : value;
    }

    if (dataType === 'Double' || dataType === 'Integer') {
      let formattedValue = numeral(value).format(format || '0,0'); // Default format: comma-separated
      return `${prefix || ''}${formattedValue}${suffix || ''}`;
    }

    if (dataType === 'Boolean') {
      return value ? 'Yes' : 'No';
    }

    // For String or other data types, return the value without formatting
    return value;
  };

  return (
    <div 
      style={{ 
        boxSizing: 'border-box',  
        overflow: 'hidden',  
        display: 'flex',  
        flexDirection: 'column',  
        height: '100vh',  
        backgroundColor: '#f4f4f4',  
        padding: '10px'  
      }}
    >
      <h2>{selectedProperty ? 'Property Details' : 'Filter Results'}</h2>

      {selectedProperty ? (
        <>
          <button 
            onClick={() => setSelectedProperty(null)} 
            style={{ marginBottom: '10px', padding: '8px 16px', cursor: 'pointer' }}
          >
            Back to Results
          </button>

          {Object.keys(groupedData).map(category => (
            <div key={category}>
              <h3>{category}</h3>
              <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                <ul style={{ margin: '0', padding: '0', flex: '1', listStyle: 'none' }}>
                  {groupedData[category].slice(0, Math.ceil(groupedData[category].length / 2)).map(col => (
                    <li key={col['Field Name']} style={{ marginBottom: '4px', lineHeight: '1.2' }}>
                      <strong>{col['Name as it appears on the website']}: </strong>
                      {/* Apply formatValue to the property details */}
                      {formatValue(
                        selectedProperty[col['Field Name']],
                        col['Num Format'],  // Format from the CSV
                        col['Prefix'],      // Prefix from the CSV
                        col['Suffix'],      // Suffix from the CSV
                        col['Data Type'],   // Data type from the CSV
                        col['Field Name'],  // Field name for custom logic
                        selectedProperty    // The full row data
                      )}
                    </li>
                  ))}
                </ul>
                <ul style={{ margin: '0', padding: '0', flex: '1', listStyle: 'none' }}>
                  {groupedData[category].slice(Math.ceil(groupedData[category].length / 2)).map(col => (
                    <li key={col['Field Name']} style={{ marginBottom: '4px', lineHeight: '1.2' }}>
                      <strong>{col['Name as it appears on the website']}: </strong>
                      {/* Apply formatValue to the property details */}
                      {formatValue(
                        selectedProperty[col['Field Name']],
                        col['Num Format'],  
                        col['Prefix'],      
                        col['Suffix'],      
                        col['Data Type'],   
                        col['Field Name'],  
                        selectedProperty    
                      )}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          ))}
        </>
      ) : (
        propertyData && propertyData.length > 0 ? (
          <div style={{ flexGrow: '1', overflowX: 'auto', overflowY: 'hidden', width: '800px', display: 'flex', flexDirection: 'column' }}>
            <DataTable 
              data={data} 
              propertyData={propertyData} 
              sortConfig={sortConfig} 
              handleSort={handleSort} 
              map={map}  
              highlightedFeatureId={highlightedFeatureId}  
              setHighlightedFeatureId={setHighlightedFeatureId}  
            />
          </div>
        ) : (
          <p>No property data available</p>
        )
      )}
    </div>
  );
};

export default FilterResultsSidebar;
